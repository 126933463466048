import React from "react"
import styled from "styled-components"

import Layout from "../components/theme/layout"
import SEO from "../components/theme/header/seo"
import SearchPageContent from "../components/search/search-page-content"

const NotFoundPage = ({ location }) => {
  const pathName = typeof window != "undefined" ? window.location.pathname : ""

  const title = "404: Not found"

  return (
    <Layout location={location}>
      <SEO title={title} location={location} yoast={{}} />
      <h1>NOT FOUND</h1>
      <p>The page that you requested doesn’t exist</p>
      <Styled404SearchResults>
        <h4>
          Search below, perhaps we could still find what you were looking for.
        </h4>
        <SearchPageContent query={pathName.replace(/(?:-|\/)/gi, " ")} />
      </Styled404SearchResults>
    </Layout>
  )
}

export default NotFoundPage

// ==========
// 	 STYLES
// ==========

const Styled404SearchResults = styled.div`
  > h4 {
    padding: 3rem 0;
  }
`
